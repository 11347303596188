export const InfoTable = () => {
  return (
    <div className='flex flex-col'>
      <table
        border={1}
        cellSpacing={1}
        cellPadding={1}
        className={`self-center text-black w-[750px]`}
      >
        <thead>
          <tr style={{ borderBottom: ' 1px solid black' }}>
            <th align='center'>
              <strong>Помещение</strong>
            </th>
            <th align='center' className='w-[320px]'>
              <strong>Значение воздухообмена</strong>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr style={{ borderBottom: ' 1px solid black' }}>
            <td>Кладовая, бельевая, гардеробная</td>
            <td align='center'>
              0,2 ч<sup>-1</sup>
            </td>
          </tr>
          <tr style={{ borderBottom: ' 1px solid black' }}>
            <td>
              Кухня (кухня-ниша, кухонная зона в кухне-столовой) с электрической
              плитой (или электрическими варочной панелью и жарочным шкафом)
            </td>
            <td align='center'>
              60 м<sup>3</sup>/ч
            </td>
          </tr>
          <tr style={{ borderBottom: ' 1px solid black' }}>
            <td>
              Кухня с газовой плитой (или газовыми варочной панелью и жарочным
              шкафом)
            </td>
            <td align='center'>
              100 м<sup>3</sup>/ч
            </td>
          </tr>
          <tr style={{ borderBottom: ' 1px solid black' }}>
            <td>
              Кухня-столовая с газовой плитой (или газовыми варочной панелью и
              жарочным шкафом)
            </td>
            <td align='center'>В соответствии с СП 402.1325800</td>
          </tr>
          <tr style={{ borderBottom: ' 1px solid black' }}>
            <td>
              Помещение с теплогенератором или водонагревателем на газовом
              топливе, газовым конвектором общей теплопроизводительностью до 50
              кВт высотой менее 6 м:
            </td>
            <td>&nbsp;</td>
          </tr>
          <tr style={{ borderBottom: ' 1px solid black' }}>
            <td>- с открытой камерой сгорания</td>
            <td align='center'>
              100 м<sup>3</sup>/ч* с учетом требований СП 402.1325800
            </td>
          </tr>
          <tr style={{ borderBottom: ' 1px solid black' }}>
            <td>- с закрытой камерой сгорания</td>
            <td align='center'>
              1,0 <sup>*,**</sup>
            </td>
          </tr>
          <tr style={{ borderBottom: ' 1px solid black' }}>
            <td>Ванная комната, душевая, совмещенный санузел</td>
            <td align='center'>
              50 м<sup>3</sup>/ч
            </td>
          </tr>
          <tr>
            <td>Уборная, туалет, постирочная</td>
            <td align='center'>
              25 м<sup>3</sup>/ч
            </td>
          </tr>
        </tbody>
      </table>

      <br />
      <br />

      <p className='text-black'>
        <span className='font-bold'>*</span> При установке в помещении кроме
        указанного оборудования:
      </p>
      <p className='text-black'>
        - газовой плиты (или газовых варочной панели и жарочного шкафа)
        воздухообмен следует увеличить на 100 м<sup>3</sup>/ч;
      </p>
      <p className='text-black'>
        - электрической плиты (или электрических варочной панели и жарочного
        шкафа) воздухообмен следует увеличить на 60 м<sup>3</sup>/ч.
      </p>
      <br />
      <p className='text-black'>
        <span className='font-bold'>**</span> Воздухообмен по кратности следует
        принять равным общему объему помещения (квартиры).
      </p>
    </div>
  );
};
