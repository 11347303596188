import { useEffect, useMemo, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  CheckOutlined,
  DownOutlined,
  EditOutlined,
  LeftOutlined,
} from '@ant-design/icons';
import { Button, Dropdown, Input, MenuProps, Tooltip, Typography } from 'antd';
import { MD5 } from 'crypto-js';

import { Breadcrumbs } from '@/components/ui/Breadcrumbs/Breadcrumbs';
import { TourItem } from '@/components/ui/TourItem/TourItem';
import { ContentWrapper } from '@/components/ui/wrappers/ContentWrapper/ContentWrapper';
import { LoadingStatus } from '@/core/enums/loadingStatus';
import { SystemType } from '@/core/enums/systemType';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { reportSelectors } from '@/core/redux/slices/smokeExtraction/system/report/selectors';
import { reportActions } from '@/core/redux/slices/smokeExtraction/system/report/slice';
import { systemSelectors } from '@/core/redux/slices/smokeExtraction/system/selectors';
import { systemActions } from '@/core/redux/slices/smokeExtraction/system/slice';
import { usersSelectors } from '@/core/redux/slices/users/selectors';

import { useCurrentCalculation } from '../hooks/useCurrentCalculation';

import { AerodynamicsView } from './views/AerodynamicsView/AerodynamicsView';
import { AirCurtainsView } from './views/AirCurtainsView/AirCurtainsView';
import { AirlockVestibulesView } from './views/AirlockVestibulesView/AirlockVestibulesView';
import { ApartmentsAirExchangeView } from './views/ApartmentsAirExchangeView/ApartmentsAirExchangeView';
import { CalculationOfSmokeRemovalDevicesView } from './views/CalculationOfSmokeRemovalDevicesView/CalculationOfSmokeRemovalDevicesView';
import { CompensatingAirSupplyView } from './views/CompensatingAirSupplyView/CompensatingAirSupplyView';
import { ElevatorShaftsView } from './views/ElevatorShaftsView/ElevatorShaftsView';
import { FacadeSmokeHatchesView } from './views/FacadeSmokeHatchesView/FacadeSmokeHatchesView';
import { FireSafeZonesView } from './views/FireSafeZonesView/FireSafeZonesView';
import { RemoveFromAdjacentToBurningView } from './views/RemoveFromAdjacentToBurningView/RemoveFromAdjacentToBurningView';
import { RemoveFromBurningView } from './views/RemoveFromBurningView/RemoveFromBurningView';
import { RoofSmokeHatchesView } from './views/RoofSmokeHatchesVIew/RoofSmokeHatchesView';
import { SmokeFreeStairwellsView } from './views/SmokeFreeStairwellsView/SmokeFreeStairwellsView';
import { SystemAlerts } from './SystemAlerts';
import { SystemInformation } from './SystemInformation';

const { Text, Title } = Typography;

export const SystemViewPage: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const params = useParams();

  const isReportReady = useAppSelector(systemSelectors.isReportReady);
  const addSystemLock = useAppSelector(systemSelectors.addSystemLock);
  const user = useAppSelector(usersSelectors.user);

  const [currentView, setCurrentView] = useState<SystemType>();
  const [isEditView, setIsEditView] = useState<boolean>(false);
  const [isSameHash, setIsSameHash] = useState<boolean>(false);
  const [editValue, setEditValue] = useState<string>('');
  const [isObserver, setIsObserver] = useState<boolean>(true);

  const system = useAppSelector(systemSelectors.system);
  const reportStatus = useAppSelector(reportSelectors.status);
  const report = useAppSelector(reportSelectors.report);

  const currentCalculation = useCurrentCalculation({
    calculationID: Number(params.calculationId),
  });

  const reportHash = useMemo(() => report?.sec_state_hash, [report]);
  const isReportLoading = useMemo(
    () => reportStatus === LoadingStatus.LOADING,
    [reportStatus]
  );

  const routerParams = useParams();

  const systemID = Number(routerParams.systemId);

  const getSystemTypeFromString = (input: string) =>
    Object.values(SystemType).find((value) => value === input);

  useEffect(() => {
    dispatch(
      systemActions.fetchSystem({
        id: systemID,
      })
    );

    return () => {
      if (user?.organization_id && system?.locker?.uuid === user?.uuid) {
        dispatch(
          systemActions.calculationLock({
            id: systemID,
            uuid: null,
          })
        );
      }
    };
  }, [isObserver]);

  useEffect(() => {
    if (!system) {
      return;
    }

    if (!user?.organization_id) {
      setIsObserver(false);
    } else if (system && system?.locker?.uuid === user?.uuid) {
      setIsObserver(false);
    }

    if (system && !system?.locker && user?.organization_id) {
      calculationLock();
    }

    const view = getSystemTypeFromString(system.type);

    setCurrentView(view);
  }, [system, addSystemLock]);

  useEffect(() => {
    dispatch(
      reportActions.fetchReport({
        id: systemID,
      })
    );

    return () => {
      dispatch(systemActions.setSystem(null));
      dispatch(reportActions.setDownloadLink(null));
      dispatch(reportActions.setReportStatus(LoadingStatus.NEVER));
    };
  }, []);

  useEffect(() => {
    const { status, reponse } = addSystemLock;
    const checkNewSystem =
      reponse && params?.systemId && +reponse?.id !== +params?.systemId;

    if (status === LoadingStatus.LOADED && checkNewSystem) {
      navigate(`/objects/${routerParams.id}/${routerParams.calculationId}/`);

      setTimeout(() => {
        navigate(
          `/objects/${routerParams.id}/${routerParams.calculationId}/system/${reponse.id}`
        );
      }, 10);
    }
  }, [addSystemLock]);

  useEffect(() => {
    return () => {
      dispatch(
        systemActions.setAddSystemLock({
          status: LoadingStatus.NEVER,
          reponse: null,
        })
      );
    };
  }, []);

  const handleCreateReport = (id: number, extension: string) => {
    if (isSameHash) {
      dispatch(
        reportActions.downloadReport({
          id: id,
          extension: extension,
        })
      );
    } else {
      dispatch(
        reportActions.createReport({
          id: id,
          extension: extension,
        })
      );
    }
  };

  const systemState = useAppSelector(systemSelectors.systemState);

  useEffect(() => {
    if (!systemState) {
      return;
    }

    const sortedKeys = Object.keys(systemState).sort();
    const sortedJsonStr = JSON.stringify(systemState, sortedKeys);
    const hashHex = MD5(sortedJsonStr).toString();

    setIsSameHash(hashHex === reportHash);
  }, [systemState, reportHash]);

  const systemViews: Record<SystemType, React.ReactNode> = {
    [SystemType.FacadeSmokeHatches]: (
      <FacadeSmokeHatchesView systemID={systemID} isObserver={isObserver} />
    ),
    [SystemType.RoofSmokeHatches]: (
      <RoofSmokeHatchesView systemID={systemID} isObserver={isObserver} />
    ),
    [SystemType.AirCompensation]: (
      <CompensatingAirSupplyView systemID={systemID} isObserver={isObserver} />
    ),
    [SystemType.AirCurtains]: (
      <AirCurtainsView systemID={systemID} isObserver={isObserver} />
    ),
    [SystemType.AirlockVestibules]: (
      <AirlockVestibulesView systemID={systemID} isObserver={isObserver} />
    ),
    [SystemType.FireSafeZones]: (
      <FireSafeZonesView systemID={systemID} isObserver={isObserver} />
    ),
    [SystemType.SmokeFreeStairwells]: (
      <SmokeFreeStairwellsView systemID={systemID} isObserver={isObserver} />
    ),
    [SystemType.RemovingFromBurning]: (
      <RemoveFromBurningView systemID={systemID} isObserver={isObserver} />
    ),
    [SystemType.RemoveFromAdjacentToBurningView]: (
      <RemoveFromAdjacentToBurningView
        systemID={systemID}
        isObserver={isObserver}
      />
    ),
    [SystemType.ElevatorShaftsView]: (
      <ElevatorShaftsView systemID={systemID} isObserver={isObserver} />
    ),
    [SystemType.AerodynamicsView]: (
      <AerodynamicsView systemID={systemID} isObserver={isObserver} />
    ),
    [SystemType.CalculationOfSmokeRemovalDevicesView]: (
      <CalculationOfSmokeRemovalDevicesView
        systemID={systemID}
        isObserver={isObserver}
      />
    ),
    [SystemType.ApartmentsAirExchangeView]: (
      <ApartmentsAirExchangeView systemID={systemID} isObserver={isObserver} />
    ),
  };

  const calculationLock = () => {
    dispatch(
      systemActions.calculationLock({
        id: systemID,
        uuid: user?.uuid || null,
      })
    );
    dispatch(
      systemActions.fetchSystem({
        id: systemID,
      })
    );
  };

  const onDownloadOptionClick: MenuProps['onClick'] = ({ key }) => {
    handleCreateReport(systemID, key);
  };

  const canCreateAerodynamics: Array<string> = [
    'Удаление продуктов горения из смежного с горящим помещения',
    'Удаление продуктов горения из горящего помещения',
    'Компенсирующая подача воздуха',
    'Воздушные завесы',
  ];

  const handleCreateAerodynamics = () => {
    if (!currentView || !system) return;
    const state = {
      tgrav1: null,
      result_table: [{}],
    };

    if (
      [
        'Удаление продуктов горения из смежного с горящим помещения',
        'Удаление продуктов горения из горящего помещения',
      ]?.includes(currentView)
    ) {
      state.tgrav1 = system?.state?.tsm;
      state.result_table = [
        {
          G_nach_uch: system?.state?.Gsm,
          t_nach_uch: system?.state?.tsm,
          i: 1,
        },
      ];
    } else if (
      ['Компенсирующая подача воздуха', 'Воздушные завесы']?.includes(
        currentView
      )
    ) {
      state.tgrav1 = system?.state?.ta;
      state.result_table = [
        { G_nach_uch: system?.state?.Ga, t_nach_uch: system?.state?.ta, i: 1 },
      ];
    }

    dispatch(
      systemActions.addSystemWithState({
        calculation_id: Number(routerParams.calculationId),
        name: system.name,
        type: SystemType.AerodynamicsView,
        state: state,
      })
    );
  };

  const continueCalculation = () => {
    dispatch(
      systemActions.calculationLock({
        id: systemID,
        uuid: user?.uuid || null,
      })
    );
  };

  const onClosePage = () => {
    if (user?.organization_id && system?.locker?.uuid === user?.uuid) {
      dispatch(
        systemActions.calculationLock({
          id: systemID,
          uuid: null,
        })
      );
    }
    navigate(`/objects/${routerParams.id}/${routerParams.calculationId}`);
  };

  const downloadOptions: MenuProps['items'] = [
    {
      label: 'Выгрузить в формате doсx',
      key: 'docx',
    },
    {
      label: 'Выгрузить в формате pdf',
      key: 'pdf',
    },
  ];
  return (
    <div>
      <Breadcrumbs
        items={[
          {
            key: 'home',
            title: 'Все расчёты',
          },
          {
            key: 'objects',
            title: <Link to={'/objects'}>Объекты строительства</Link>,
          },
          ...(currentCalculation
            ? [
                {
                  key: 'currentObject',
                  title: (
                    <Link to={`/objects/${params.id}`}>
                      {currentCalculation.object_name}
                    </Link>
                  ),
                },
                {
                  key: 'system',
                  title: (
                    <Link to={`/objects/${params.id}/${params.calculationId}`}>
                      {currentCalculation.calc_name}
                    </Link>
                  ),
                },
                {
                  key: 'currentSystem',
                  title: system?.name,
                },
              ]
            : []),
        ]}
      />
      <ContentWrapper>
        <div>
          <div className='px-8 py-4'>
            <div className='flex justify-between'>
              <div className='flex gap-4 items-center'>
                <Button icon={<LeftOutlined />} onClick={onClosePage}></Button>
                {system && (
                  <div className='flex flex-col'>
                    <div className='flex gap-2 items-center'>
                      {isEditView ? (
                        <>
                          <Input
                            defaultValue={system.name}
                            onChange={(e) =>
                              setEditValue(e.currentTarget.value)
                            }
                            value={editValue || system.name}
                          />
                          <CheckOutlined
                            className='text-primary cursor-pointer'
                            onClick={() => {
                              setIsEditView(false);
                              dispatch(
                                systemActions.updateSystem({
                                  systemID: systemID,
                                  name: editValue,
                                })
                              );
                            }}
                          />
                        </>
                      ) : (
                        <>
                          <Title level={5} className='!m-0'>
                            {editValue || system.name}
                          </Title>
                          <EditOutlined
                            className='text-primary cursor-pointer'
                            onClick={() => {
                              setIsEditView(true);
                            }}
                          />
                        </>
                      )}
                    </div>

                    <div className='flex gap-2'>
                      <Text>{system.type}</Text>{' '}
                      {!isObserver && (
                        <Text type='secondary'>
                          Сохраняется автоматически...
                        </Text>
                      )}
                    </div>
                  </div>
                )}
              </div>
              <div className='flex items-end'>
                {currentView &&
                  canCreateAerodynamics?.includes(currentView) && (
                    <Button
                      className='mr-2'
                      disabled={!isReportReady || isObserver}
                      onClick={handleCreateAerodynamics}
                    >
                      Создать аэродинамический расчет
                    </Button>
                  )}
                <TourItem
                  className='w-[292px]'
                  content='После завершения расчета нажмите "Сформировать отчет", чтобы создать печатную форму. Таким образом, вы получите отчет, готовый к печати и дальнейшему использованию.'
                  step={9}
                  placement='bottomRight'
                >
                  {process.env.REACT_APP_ENABLE_PDF === 'enable' ? (
                    <Dropdown
                      menu={{
                        items: downloadOptions,
                        onClick: onDownloadOptionClick,
                      }}
                      trigger={['click']}
                    >
                      <Tooltip
                        placement='bottom'
                        title={isReportReady ? undefined : 'Заполните все поля'}
                      >
                        <Button
                          loading={isReportLoading}
                          type='primary'
                          icon={<DownOutlined />}
                          iconPosition='end'
                          disabled={!isReportReady || isObserver}
                        >
                          Сформировать отчет
                        </Button>
                      </Tooltip>
                    </Dropdown>
                  ) : (
                    <Tooltip
                      placement='bottom'
                      title={isReportReady ? undefined : 'Заполните все поля'}
                    >
                      <Button
                        className='w-fit'
                        loading={isReportLoading}
                        type='primary'
                        disabled={!isReportReady || isObserver}
                        onClick={() => handleCreateReport(systemID, 'docx')}
                      >
                        Сформировать отчет
                      </Button>
                    </Tooltip>
                  )}
                </TourItem>
              </div>
            </div>
          </div>

          {system && user?.organization_id && (
            <SystemAlerts
              user={user}
              system={system}
              onClosePage={onClosePage}
              systemID={systemID}
              isObserver={isObserver}
              calculationLock={calculationLock}
            />
          )}
          {system && user?.organization_id && !isObserver && (
            <SystemInformation
              system={system}
              onClosePage={onClosePage}
              continueCalculation={continueCalculation}
            />
          )}
          <div className='overflow-auto h-[calc(100vh_-_285px)]'>
            <div className='px-8 py-4 '>
              {currentView && systemViews[currentView]}
            </div>
          </div>
        </div>
      </ContentWrapper>
    </div>
  );
};
