import { ReactNode, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import {
  Input,
  InputNumber,
  InputNumberProps,
  Tooltip,
  Typography,
} from 'antd';
import { valueType } from 'antd/es/statistic/utils';
import { TooltipPlacement } from 'antd/es/tooltip';

interface InputNumberForm extends InputNumberProps {
  label?: any;
  name: string;
  defaultValue?: number;
  fixValue?: number | null;
  render?: (value: any | null | undefined) => any | null | undefined;
  note?: string;
  tooltipTitle?: string;
  tooltipPlacement?: TooltipPlacement;
}

export const InputNumberForm: React.FC<InputNumberForm> = (props) => {
  const { Text } = Typography;

  const { label, name, defaultValue, fixValue = 3, note, ...restProps } = props;

  const {
    control,
    formState: { errors },
  } = useFormContext();

  const error = errors[name];

  const renderPrefix = (): ReactNode => {
    if (props.tooltipTitle) {
      return (
        <Tooltip
          placement={props.tooltipPlacement || 'leftBottom'}
          title={props.tooltipTitle}
        >
          {props.prefix}
        </Tooltip>
      );
    } else {
      <>{props.prefix}</>;
    }
  };

  return (
    <>
      <div className='flex flex-col gap-1 relative'>
        {label && (
          <label htmlFor={name} className='text-secondary'>
            {label}
          </label>
        )}
        <Controller
          render={({ field }) => (
            <InputNumber
              {...field}
              size='large'
              {...restProps}
              onBlur={(event) => {
                props.onBlur?.(event);
                field.onBlur();
              }}
              value={
                field.value
                  ? fixValue !== null
                    ? Number(field.value).toFixed(fixValue)
                    : Number(field.value)
                  : null
              }
              status={error && 'error'}
              className='w-full'
              decimalSeparator=','
              prefix={renderPrefix()}
            />
          )}
          control={control}
          name={name}
          defaultValue={defaultValue}
        />
        {note && (
          <Typography.Text
            type='secondary'
            className='text-xs absolute -bottom-6'
          >
            {note}
          </Typography.Text>
        )}
        {error && (
          <Text className='block' type='danger'>
            {String(error.message)}
          </Text>
        )}
      </div>
    </>
  );
};
