import { PayloadAction } from '@reduxjs/toolkit';
import { notification } from 'antd';
import { NormalizeOAS, OASOutput, OASRequestParams } from 'fets';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import { authAdd, restCall } from '@/core/clients/rest';
import type oas from '@/services/rest/base/openapi';

import { systemSelectors } from '../selectors';
import { systemActions } from '../slice';

import {
  IHatchTableCalculationPayload,
  ISystemPerfomCalculationPayload,
  systemCalculationsActions,
} from './slice';

type FacadeSmokeHatchesRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/smoke_extraction/facade_smoke_hatches',
  'post'
>;

type RoofSmokeHatchesRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/smoke_extraction/roof_smoke_hatches',
  'post'
>;

type CompensatingAirSupplyRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/smoke_extraction/compensating_air_supply',
  'post'
>;

type AirCurtainsRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/smoke_extraction/air_curtains',
  'post'
>;

type AirLocksRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/smoke_extraction/air_locks',
  'post'
>;

type FireProofRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/smoke_extraction/fireproof_zones',
  'post'
>;

type SmokeFreeStairwellsRequest = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/smoke_extraction/smoke_free_stairwells',
  'post'
>;

type RemoveFromBurning = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/smoke_extraction/removing_from_burning',
  'post'
>;

type RemoveFromAdjacentToBurning = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/smoke_extraction/removing_from_adjacent_to_burning',
  'post'
>;

type ElevatorShafts = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/smoke_extraction/elevator_shafts',
  'post'
>;

type Aerodynamics = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/smoke_extraction/aerodynamics',
  'post'
>;

type CalculationOfSmokeRemovalDevices = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/smoke_extraction/smoke_extraction_devices',
  'post'
>;

type ApartmentsAirExchange = OASRequestParams<
  NormalizeOAS<typeof oas>,
  '/smoke_extraction/apartments_air_exchange',
  'post'
>;

// helpers

const showNotificationError = (error: any) => {
  if (error?.message === 'Расчет заблокирован другим пользователем') {
    notification.warning({
      message: 'Уведомление',
      description: 'Расчет заблокирован другим пользователем',
    });
  } else {
    notification.error({
      message: 'Ошибка в расчете',
      description: 'Укажите другие данные',
    });
  }
};

// Facade Smoke Hatches

function* performFacadeSmokeHatches(
  action: PayloadAction<ISystemPerfomCalculationPayload>
): Generator<any, void, any> {
  const { calc_id, data } = action.payload;

  const system = yield select(systemSelectors.system);

  const request: FacadeSmokeHatchesRequest = {
    json: {
      calc_id: calc_id,
      state: data,
    },
    ...authAdd(),
  };

  try {
    const systemCalculationResponse = yield call(
      restCall,
      '/smoke_extraction/facade_smoke_hatches',
      'post',
      request
    );

    const stateData = systemCalculationResponse.state;

    if (!stateData) {
      throw new Error();
    }

    const isSmokeHatchesSelectionEnabled =
      systemCalculationResponse.state.smoke_hatches_selection_enabled;

    if (isSmokeHatchesSelectionEnabled) {
      yield put(
        systemCalculationsActions.performHatchTableCalculation({
          product_id: Number(stateData.smoke_hatches_selection.model_id),
          data: stateData,
          position: 'Стена',
        })
      );
    }

    yield put(
      systemActions.setSystem({
        ...system,
        state: stateData,
      })
    );

    yield put(systemActions.setIsReportReady(stateData.finished));
  } catch (error) {
    console.log('Error on perform calculation', error);
    showNotificationError(error);
  }
}

// Roof Smoke Hatches

function* performRoofSmokeHatches(
  action: PayloadAction<ISystemPerfomCalculationPayload>
): Generator<any, void, any> {
  const { calc_id, data } = action.payload;

  const system = yield select(systemSelectors.system);

  const request: RoofSmokeHatchesRequest = {
    json: {
      calc_id: calc_id,
      state: data,
    },
    ...authAdd(),
  };

  try {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    const systemCalculationResponse = yield call(
      restCall,
      '/smoke_extraction/roof_smoke_hatches',
      'post',
      request
    );

    const stateData = systemCalculationResponse.state;

    if (!stateData) {
      throw new Error();
    }

    const isSmokeHatchesSelectionEnabled =
      systemCalculationResponse.state.smoke_hatches_selection_enabled;

    if (isSmokeHatchesSelectionEnabled) {
      yield put(
        systemCalculationsActions.performHatchTableCalculation({
          product_id: Number(stateData.smoke_hatches_selection.model_id),
          data: stateData,
          position: 'Кровля',
        })
      );
    }

    yield put(
      systemActions.setSystem({
        ...system,
        state: stateData,
      })
    );

    yield put(systemActions.setIsReportReady(stateData.finished));
  } catch (error) {
    console.log('Error on perform calculation', error);
    showNotificationError(error);
  }
}

function* performHatchTableCalculation(
  action: PayloadAction<IHatchTableCalculationPayload>
): Generator<any, void, any> {
  const { product_id, data, position } = action.payload;

  const request: any = {
    json: {
      position,
      state: data,
    },
    ...authAdd(),
  };

  try {
    const response = yield call(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      restCall,
      `/hatches/product/${product_id}/table`,
      'post',
      request
    );

    if (!response) {
      throw new Error();
    }

    yield put(systemCalculationsActions.setSmokeHatchesTable(response));
  } catch (error) {
    console.log('Error on perform hatch table calculation', error);
    showNotificationError(error);
  }
}

// Compensating Air Supply

function* performCompensatingAirSupply(
  action: PayloadAction<ISystemPerfomCalculationPayload>
): Generator<any, void, any> {
  const { calc_id, data } = action.payload;

  const system = yield select(systemSelectors.system);

  const request: CompensatingAirSupplyRequest = {
    json: {
      calc_id: calc_id,
      state: data,
    },
    ...authAdd(),
  };

  try {
    const response = yield call(
      restCall,
      '/smoke_extraction/compensating_air_supply',
      'post',
      request
    );

    if (!response.state) {
      throw new Error();
    }

    yield put(
      systemActions.setSystem({
        ...system,
        state: response.state,
      })
    );

    yield put(systemActions.setIsReportReady(response.state.finished));
  } catch (error) {
    console.log('Error on perform calculation', error);
    showNotificationError(error);
  }
}

// Air Curtains

function* performAirCurtains(
  action: PayloadAction<ISystemPerfomCalculationPayload>
): Generator<any, void, any> {
  const { calc_id, data } = action.payload;

  const system = yield select(systemSelectors.system);

  const request: AirCurtainsRequest = {
    json: {
      calc_id: calc_id,
      state: data,
    },
    ...authAdd(),
  };

  try {
    const response = yield call(
      restCall,
      '/smoke_extraction/air_curtains',
      'post',
      request
    );

    if (!response.state) {
      throw new Error();
    }

    yield put(
      systemActions.setSystem({
        ...system,
        state: response.state,
      })
    );

    yield put(systemActions.setIsReportReady(response.state.finished));
  } catch (error) {
    console.log('Error on perform calculation', error);
    showNotificationError(error);
  }
}

// Air Locks

function* performAirLocks(
  action: PayloadAction<ISystemPerfomCalculationPayload>
): Generator<any, void, any> {
  const { calc_id, data } = action.payload;

  const system = yield select(systemSelectors.system);

  const request: AirLocksRequest = {
    json: {
      calc_id: calc_id,
      state: data,
    },
    ...authAdd(),
  };

  try {
    const response = yield call(
      restCall,
      '/smoke_extraction/air_locks',
      'post',
      request
    );

    if (!response.state) {
      throw new Error();
    }

    yield put(
      systemActions.setSystem({
        ...system,
        state: response.state,
      })
    );

    yield put(systemActions.setIsReportReady(response.state.finished));
  } catch (error) {
    console.log('Error on perform calculation', error);
    showNotificationError(error);
  }
}

// Fire Proof Zones

function* performFireProofZones(
  action: PayloadAction<ISystemPerfomCalculationPayload>
): Generator<any, void, any> {
  const { calc_id, data } = action.payload;

  const system = yield select(systemSelectors.system);

  const request: FireProofRequest = {
    json: {
      calc_id: calc_id,
      state: data,
    },
    ...authAdd(),
  };

  try {
    const response = yield call(
      restCall,
      '/smoke_extraction/fireproof_zones',
      'post',
      request
    );

    if (!response.state) {
      throw new Error();
    }

    yield put(
      systemActions.setSystem({
        ...system,
        state: response.state,
      })
    );

    yield put(systemActions.setIsReportReady(response.state.finished));
  } catch (error) {
    console.log('Error on perform calculation', error);
    showNotificationError(error);
  }
}

// Smoke Free Stairwells

function* performSmokeFreeStairwells(
  action: PayloadAction<ISystemPerfomCalculationPayload>
): Generator<any, void, any> {
  const { calc_id, data } = action.payload;

  const system = yield select(systemSelectors.system);

  const request: SmokeFreeStairwellsRequest = {
    json: {
      calc_id: calc_id,
      state: data,
    },
    ...authAdd(),
  };

  try {
    const response = yield call(
      restCall,
      '/smoke_extraction/smoke_free_stairwells',
      'post',
      request
    );

    if (!response.state) {
      throw new Error();
    }

    yield put(
      systemActions.setSystem({
        ...system,
        state: response.state,
      })
    );

    yield put(systemActions.setIsReportReady(response.state.finished));
  } catch (error) {
    console.log('Error on perform calculation', error);
    showNotificationError(error);
  }
}

// Remove from burning

function* performRemovingFromBurning(
  action: PayloadAction<ISystemPerfomCalculationPayload>
): Generator<any, void, any> {
  const { calc_id, data } = action.payload;

  const system = yield select(systemSelectors.system);

  const request: RemoveFromBurning = {
    json: {
      calc_id: calc_id,
      state: data,
    },
    ...authAdd(),
  };

  try {
    const response = yield call(
      restCall,
      '/smoke_extraction/removing_from_burning',
      'post',
      request
    );

    if (!response.state) {
      throw new Error();
    }

    yield put(
      systemActions.setSystem({
        ...system,
        state: response.state,
      })
    );

    yield put(systemActions.setIsReportReady(response.state.finished));
  } catch (error) {
    console.log('Error on perform calculation', error);
    showNotificationError(error);
  }
}

// Remove from adjacent to burning

function* performRemovingFromAdjacentToBurning(
  action: PayloadAction<ISystemPerfomCalculationPayload>
): Generator<any, void, any> {
  const { calc_id, data } = action.payload;

  const system = yield select(systemSelectors.system);

  const request: RemoveFromAdjacentToBurning = {
    json: {
      calc_id: calc_id,
      state: data,
    },
    ...authAdd(),
  };

  try {
    const response = yield call(
      restCall,
      '/smoke_extraction/removing_from_adjacent_to_burning',
      'post',
      request
    );

    if (!response.state) {
      throw new Error();
    }

    yield put(
      systemActions.setSystem({
        ...system,
        state: response.state,
      })
    );

    yield put(systemActions.setIsReportReady(response.state.finished));
  } catch (error) {
    console.log('Error on perform calculation', error);
    showNotificationError(error);
  }
}

// Aerodynamics

function* performElevatorShafts(
  action: PayloadAction<ISystemPerfomCalculationPayload>
): Generator<any, void, any> {
  const { calc_id, data } = action.payload;

  const system = yield select(systemSelectors.system);

  const request: Aerodynamics = {
    json: {
      calc_id: calc_id,
      state: data,
    },
    ...authAdd(),
  };

  try {
    const response = yield call(
      restCall,
      '/smoke_extraction/elevator_shafts',
      'post',
      request
    );

    if (!response.state) {
      throw new Error();
    }

    yield put(
      systemActions.setSystem({
        ...system,
        state: response.state,
      })
    );

    yield put(systemActions.setIsReportReady(response.state.finished));
  } catch (error) {
    console.log('Error on perform calculation', error);
    showNotificationError(error);
  }
}

function* performAerodynamics(
  action: PayloadAction<ISystemPerfomCalculationPayload>
): Generator<any, void, any> {
  const { calc_id, data } = action.payload;

  const system = yield select(systemSelectors.system);

  const request: Aerodynamics = {
    json: {
      calc_id: calc_id,
      state: data,
    },
    ...authAdd(),
  };

  try {
    const response = yield call(
      restCall,
      '/smoke_extraction/aerodynamics',
      'post',
      request
    );

    if (!response.state) {
      throw new Error();
    }

    yield put(
      systemActions.setSystem({
        ...system,
        state: response.state,
      })
    );

    yield put(systemActions.setIsReportReady(response.state.finished));
  } catch (error) {
    console.log('Error on perform calculation', error);
    showNotificationError(error);
  }
}

// Calculation Of Smoke Extraction Devices

function* performCalculationOfSmokeRemovalDevices(
  action: PayloadAction<ISystemPerfomCalculationPayload>
): Generator<any, void, any> {
  const { calc_id, data } = action.payload;

  const system = yield select(systemSelectors.system);

  const request: CalculationOfSmokeRemovalDevices = {
    json: {
      calc_id: calc_id,
      state: data,
    },
    ...authAdd(),
  };

  try {
    const response = yield call(
      restCall,
      '/smoke_extraction/smoke_extraction_devices',
      'post',
      request
    );

    if (!response.state) {
      throw new Error();
    }

    yield put(
      systemActions.setSystem({
        ...system,
        state: response.state,
      })
    );

    yield put(systemActions.setIsReportReady(response.state.finished));
  } catch (error) {
    console.log('Error on perform calculation', error);
    showNotificationError(error);
  }
}

// Apartments air exchange

function* performApartmentsAirExchange(
  action: PayloadAction<ISystemPerfomCalculationPayload>
): Generator<any, void, any> {
  const { calc_id, data } = action.payload;

  const system = yield select(systemSelectors.system);

  const request: ApartmentsAirExchange = {
    json: {
      calc_id: calc_id,
      state: data,
    },
    ...authAdd(),
  };

  try {
    const response = yield call(
      restCall,
      '/smoke_extraction/apartments_air_exchange',
      'post',
      request
    );

    if (!response.state) {
      throw new Error();
    }

    yield put(
      systemActions.setSystem({
        ...system,
        state: {
          ...response.state,
          natural_ventilation: response?.state?.natural_ventilation
            ? 'true'
            : 'false',
        },
      })
    );

    yield put(systemActions.setIsReportReady(response.state.finished));
  } catch (error) {
    console.log('Error on perform calculation', error);
    showNotificationError(error);
  }
}

export const systemCalculationsSagas = [
  takeLatest(
    systemCalculationsActions.performFacadeSmokeHatches,
    performFacadeSmokeHatches
  ),
  takeLatest(
    systemCalculationsActions.performRoofSmokeHatches,
    performRoofSmokeHatches
  ),
  takeLatest(
    systemCalculationsActions.performCompensatingAirSupply,
    performCompensatingAirSupply
  ),
  takeLatest(systemCalculationsActions.performAirCurtains, performAirCurtains),
  takeLatest(systemCalculationsActions.performAirLocks, performAirLocks),
  takeLatest(
    systemCalculationsActions.performFireProofZones,
    performFireProofZones
  ),
  takeLatest(
    systemCalculationsActions.performSmokeFreeStairwells,
    performSmokeFreeStairwells
  ),
  takeLatest(
    systemCalculationsActions.performRemovingFromBurning,
    performRemovingFromBurning
  ),
  takeLatest(
    systemCalculationsActions.performRemovingFromAdjacentToBurning,
    performRemovingFromAdjacentToBurning
  ),
  takeLatest(
    systemCalculationsActions.performElevatorShafts,
    performElevatorShafts
  ),

  takeLatest(
    systemCalculationsActions.performAerodynamics,
    performAerodynamics
  ),
  takeLatest(
    systemCalculationsActions.performHatchTableCalculation,
    performHatchTableCalculation
  ),
  takeLatest(
    systemCalculationsActions.performCalculationOfSmokeRemovalDevices,
    performCalculationOfSmokeRemovalDevices
  ),
  takeLatest(
    systemCalculationsActions.performApartmentsAirExchange,
    performApartmentsAirExchange
  ),
];
