import { FocusEvent, useEffect, useState } from 'react';
import {
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
} from 'react-hook-form';
import {
  DeleteOutlined,
  PlusOutlined,
  QuestionCircleTwoTone,
} from '@ant-design/icons';
import { Button, ConfigProvider, Modal } from 'antd';
import { cloneDeep } from 'lodash';

import { InputNumberForm } from '@/components/form/InputNumber';
import { SelectForm } from '@/components/form/Select';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { systemCalculationsActions } from '@/core/redux/slices/smokeExtraction/system/calculations/slice';
import { systemSelectors } from '@/core/redux/slices/smokeExtraction/system/selectors';

import { InfoTable } from './InformationTable';

export interface IApartmentsAirExchangeView {
  systemID: number;
  isObserver: boolean;
}

interface IArrayAirFromApartment {
  handlePerformCalculation: () => void;
  handleBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
  isObserver: boolean;
}

const ArrayAirFromApartment: React.FC<IArrayAirFromApartment> = ({
  handleBlur,
  isObserver,
  handlePerformCalculation,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { control, register } = useFormContext();

  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray(
    {
      control,
      name: 'Lv_i',
    }
  );

  const handleAddField = () => {
    append('0');
    handlePerformCalculation();
  };

  const handleRemoveField = (index: number) => {
    remove(index);
    handlePerformCalculation();
  };

  return fields?.map((field, index) => (
    <div className='mb-4 flex justify-between items-end gap-2' key={field?.id}>
      <div className='w-full'>
        <InputNumberForm
          fixValue={2}
          addonAfter={
            <i>
              м<sup>3</sup>
              /ч
            </i>
          }
          name={`Lv_i.${index}`}
          label={
            index === 0 ? (
              <div>
                Расход вытяжного воздуха из квартиры{' '}
                <QuestionCircleTwoTone
                  style={{ color: 'blue' }}
                  onClick={() => setIsModalOpen(true)}
                />
              </div>
            ) : (
              ''
            )
          }
          placeholder={isObserver ? '' : 'Введите значение'}
          onBlur={handleBlur}
          size='large'
          disabled={isObserver}
        />
      </div>
      <div className='flex gap-2'>
        <Button
          disabled={isObserver}
          icon={<PlusOutlined />}
          onClick={handleAddField}
        />
        <Button
          disabled={fields?.length === 1 || isObserver}
          icon={<DeleteOutlined />}
          onClick={() => {
            handleRemoveField(index);
          }}
        />
        {index === 0 && (
          <ConfigProvider
            theme={{
              components: {
                Modal: {
                  contentBg: '#d9d9d9',
                },
              },
            }}
          >
            <Modal
              width={850}
              mask={false}
              footer={null}
              open={isModalOpen}
              onCancel={() => setIsModalOpen(false)}
              centered
            >
              <InfoTable />
            </Modal>
          </ConfigProvider>
        )}
      </div>
    </div>
  ));
};

export const ApartmentsAirExchangeView: React.FC<
  IApartmentsAirExchangeView
> = ({ systemID, isObserver }) => {
  const systemState = useAppSelector(systemSelectors.systemState);
  const dispatch = useAppDispatch();

  const methods = useForm<any>({
    defaultValues: {
      So: null,
      Szh: null,
      h: null,
      Vo: null,
      N: null,
      so_ud: null,
      natural_ventilation: 'true',
      Lp_rasch: null,
      Lv_i: ['0'],
      sum_Lv: null,
      Lp_itog: null,
    },
  });

  const handlePerformCalculation = () => {
    const data = methods.getValues();

    const cloneData = cloneDeep(data);

    dispatch(
      systemCalculationsActions.performApartmentsAirExchange({
        calc_id: systemID,
        data: {
          ...cloneData,
          natural_ventilation:
            cloneData?.natural_ventilation === 'false' ? false : true,
        },
      })
    );
  };

  const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    if (!systemState || systemState[name] !== value) {
      handlePerformCalculation();
    }
  };

  useEffect(() => {
    systemState &&
      methods.reset({
        ...systemState,
        natural_ventilation:
          systemState.natural_ventilation === true ||
          systemState.natural_ventilation === 'true'
            ? 'true'
            : 'false',
      });
    !systemState && handlePerformCalculation();
  }, [systemState]);

  const selectOptions = [
    {
      value: 'true',
      label: 'Да',
    },
    {
      value: 'false',
      label: 'Нет',
    },
  ];

  return (
    <FormProvider {...methods}>
      <form>
        <div className='flex flex-col gap-8'>
          <div className='py-4 px-2'>
            <div className='grid grid-cols-2 gap-4 gap-y-8 items-end'>
              <InputNumberForm
                fixValue={2}
                addonBefore={<i>So =</i>}
                addonAfter={
                  <i>
                    м<sup>2</sup>
                  </i>
                }
                name='So'
                label='Общая площадь квартиры'
                placeholder={isObserver ? '' : 'Введите значение'}
                onBlur={handleBlur}
                size='large'
                disabled={isObserver}
              />
              <InputNumberForm
                fixValue={2}
                addonBefore={<i>Sж =</i>}
                addonAfter={
                  <i>
                    м<sup>2</sup>
                  </i>
                }
                name='Szh'
                label='Жилая площадь квартиры'
                placeholder={isObserver ? '' : 'Введите значение'}
                onBlur={handleBlur}
                size='large'
                disabled={isObserver}
              />
              <InputNumberForm
                fixValue={2}
                addonBefore={<i>h =</i>}
                addonAfter={<i>м</i>}
                name='h'
                label='Высота квартиры'
                placeholder={isObserver ? '' : 'Введите значение'}
                onBlur={handleBlur}
                size='large'
                disabled={isObserver}
              />
              <InputNumberForm
                fixValue={1}
                addonBefore={
                  <i>
                    V<sub>0</sub> =
                  </i>
                }
                addonAfter={
                  <i>
                    м<sup>3</sup>
                  </i>
                }
                name='Vo'
                label='Общий объем квартиры'
                onBlur={handleBlur}
                size='large'
                disabled
              />
              <InputNumberForm
                fixValue={0}
                addonBefore={<i>N =</i>}
                addonAfter={<i>чел</i>}
                name='N'
                label='Количество человек в квартире'
                placeholder={isObserver ? '' : 'Введите значение'}
                onBlur={handleBlur}
                size='large'
                disabled={isObserver}
              />
              <InputNumberForm
                fixValue={1}
                addonBefore={
                  <span>
                    S<sub>0 уд</sub> =
                  </span>
                }
                addonAfter={
                  <i>
                    м<sup>2</sup>
                    /чел.
                  </i>
                }
                name='so_ud'
                label='Общая площадь квартиры, приходящаяся на 1 человека'
                onBlur={handleBlur}
                size='large'
                disabled
              />
              <SelectForm
                label='Естественное проветривание'
                size='large'
                options={selectOptions}
                name={`natural_ventilation`}
                onChange={() => handlePerformCalculation()}
                disabled={isObserver}
              />

              <InputNumberForm
                fixValue={0}
                addonBefore={
                  <span>
                    L<sub>п. расч</sub> =
                  </span>
                }
                addonAfter={
                  <i>
                    м<sup>3</sup>
                    /ч
                  </i>
                }
                name='Lp_rasch'
                label='Расчетный расход приточного воздуха'
                onBlur={handleBlur}
                size='large'
                disabled
              />

              <div>
                <ArrayAirFromApartment
                  handleBlur={handleBlur}
                  isObserver={isObserver}
                  handlePerformCalculation={handlePerformCalculation}
                />
              </div>

              <div></div>

              <div className='font-bold'>
                <InputNumberForm
                  fixValue={2}
                  addonBefore={
                    <i>
                      ∑L<sub>B</sub> =
                    </i>
                  }
                  addonAfter={
                    <i>
                      м<sup>3</sup>
                      /ч
                    </i>
                  }
                  name='sum_Lv'
                  label='Суммарный расход вытяжного воздуха из квартиры'
                  onBlur={handleBlur}
                  size='large'
                  disabled
                />
              </div>
              <div className='font-bold'>
                <InputNumberForm
                  fixValue={2}
                  addonBefore={
                    <span>
                      L<sub>п. итог</sub> =
                    </span>
                  }
                  addonAfter={
                    <i>
                      м<sup>3</sup>
                      /ч
                    </i>
                  }
                  name='Lp_itog'
                  label='Итоговое значение расхода приточного воздуха'
                  onBlur={handleBlur}
                  size='large'
                  disabled
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};
