export enum SystemType {
  AerodynamicsView = 'Аэродинамический расчет',
  CalculationOfSmokeRemovalDevicesView = 'Расчет количества дымоприемных устройств',
  RemoveFromAdjacentToBurningView = 'Удаление продуктов горения из смежного с горящим помещения',
  RemovingFromBurning = 'Удаление продуктов горения из горящего помещения',
  RoofSmokeHatches = 'Дымовые люки в кровле',
  FacadeSmokeHatches = 'Дымовые люки на фасаде',
  AirCompensation = 'Компенсирующая подача воздуха',
  ElevatorShaftsView = 'Лифтовые шахты',
  SmokeFreeStairwells = 'Лестничные клетки',
  FireSafeZones = 'Пожаробезопасные зоны',
  AirlockVestibules = 'Тамбур-шлюзы',
  AirCurtains = 'Воздушные завесы',
  ApartmentsAirExchangeView = 'Расчет воздухообмена квартир',
}
